import { Action } from '@ngrx/store';

export enum LoadingAnimActionTypes {
  Show = '[Loading Anim] Show',
  Hide = '[Loading Anim] Hide'
}

export class Show implements Action {
  readonly type = LoadingAnimActionTypes.Show;
}

export class Hide implements Action {
  readonly type = LoadingAnimActionTypes.Hide;
}

export type LoadingAnimActions = Show | Hide;

